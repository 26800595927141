
//筛选条件类型接口
interface ScreeningConditionsType {
    vinNo: null | string;
    modelName: null | string;
    retailDate: string[];
    saleDate: string[];
    vinNoList: string[];
}
//上传支持文档类型
interface SupportDocVOSType {
    id: string;
    supportDocName: string;
    supportDocFilePath: string;
    isRequired: string | boolean;
}
//表格类型
interface DealerVINList {
    id: string;
    vinNo: string;
    modelName: string;
    modelYear: string;
    retailDate: string;
    saleDate: string;
    financialApplicationDate: string;
    customerName: string;
    specialVehicleUse: string;
    extendedInfo: string;
    supportDocVOS: SupportDocVOSType[];
    customerPolicyEstimatedAmount: string;
    checkStatus: string;
    check: boolean;
    //新增的
    commissionNo: string;
    engineNo: string;
    entity: string;
    fin: string;
    materialNo: string;
    programBu: string;
    programCode: string;
    programCreator: string;
    programName: string;
    programNameCn: string;
    programReleaseDate: string;
    supportOfferId: string;
    supportOfferName: string;
    typeClass: string;
    programBuSecond: string;
    make: string;
    siOffer: string;
    orderProtection: boolean;
    presale: boolean;
    conflict?: any;
}
import downloadFile from '@/utils/claim/downloadFile';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { message, Modal } from 'ant-design-vue';
import moment from 'moment';
import SingleInformation from './ExtendInformation/SingleInformation.vue';
import UploadInformation from './ExtendInformation/UploadInformation.vue';
import SupportExample from '../UploadSupport/UploadExample.vue';
import UploadSupport from '../UploadSupport/index.vue';
import { useRouter } from 'vue-router';
import { Moment } from 'moment';
import {
    defineComponent,
    h,
    createVNode,
    ref,
    computed,
    reactive,
    onMounted,
    nextTick,
    toRefs,
} from 'vue';
import {
    getVINTableData,
    setClaimDocumentMatching,
    downloadExtendInfoApi,
    supportReview,
    saveDealerClaimApp,
    docSample,
    validateReplaceAttributes,
} from '@/API/claim/delaerClaim/delaer';
import {
  getBuTreeList
} from '@/API/approvalChain';
import type { BuTreeProps } from '@/store'

function getFirstLevelBU(buTree: BuTreeProps[], bu: string) {
    for (const buItem of buTree) {
        if (buItem.nameEn === bu || getFirstLevelBU(buItem.children, bu)) {
            return buItem
        }
    }
    return null;
}

export default defineComponent({
    components: {
        SingleInformation,
        UploadInformation,
        SupportExample,
        UploadSupport,
        ExclamationCircleOutlined,
    },
    emits: ['pre-page'],
    setup(props, ctx) {
        // //map对象，用于缓存总数据
        // const scoreMap = ref<any>();
        // //是否是第一次加载
        // let isFirst = true;

        const errorModalVisible = ref(false);
        let errorArray: Array<string> = [];
        // const errorDataSource,errorColumns
        const errorDataSource = ref<any[]>([]);
        const errorColumns = [
            {
                title: '车架号',
                dataIndex: 'VINNo',
                key: 'VINNo',
            },
            {
                title: '错误信息',
                dataIndex: 'message',
                key: 'message',
            },
        ];

        const router = useRouter();
        //填写扩展信息子页面
        const extendInfoDom = ref<any>(null);
        //上传批量扩展信息子页面
        const uploadInfoDom = ref<any>(null);
        //显示样例子页面
        const supportExample = ref<any>(null);
        //批量上传支持文档
        const uploadSupportDom = ref<any>(null);
        //参数
        const option = ref<any>({});
        const categoryName = ref('');

        //按钮文字
        const nextButtonText = ref('下一步');

        //证明文件名称
        const proveText = ref('aaaa.pdf');

        //区域经理
        const manager = ref('');

        //数据总数
        const dataCount = ref(0);

        //筛选条件
        const screeningConditions: ScreeningConditionsType = reactive({
            vinNo: null,
            modelName: null,
            retailDate: [],
            saleDate: [],
            vinNoList: [],
        });

        //筛选条件查询
        const screeningConditionsTemporary: ScreeningConditionsType = reactive({
            vinNo: null,
            modelName: null,
            retailDate: [],
            saleDate: [],
            vinNoList: [],
        });

        //当前步骤记录
        const currentStep = ref(0);
        //步骤条
        const stepArray = ref<string[]>([]);
        //步骤的编号，用于显示对应的安修和文件预览
        const stepNum = ref(1);

        //支持文档样例
        const supportSample = ref<any[]>([]);

        //表格
        //是否高量显示扩展字段
        const extendActive = ref(true);
        const extendActiveTitle = ref(true);
        //是否是上传支持图片
        const supportActive = ref(false);

        const supportActiveTitle = ref(false);
        //用于刷新数据时，确定现在还有多少数据剩余，避免显示全部数据
        const stepTableArray = ref<string[]>([]);

        //应对CR，新增上一步按钮，添加步骤存储，存储从1->2 ,2->3 ,1-3>时，未对数据进行筛选前的，vinNoArray，用于上一步去判断
        const stepTableArrayDoc = ref<string[]>([]);
        const stepTableArraySupport = ref<string[]>([]);

        const dataSource = ref<DealerVINList[]>([]);
        //上传扩展信息标题长度
        const supportDocSign = ref(false);
        const supportDocVosLength = ref(150);

        const columnsValue = [
            // {
            //   title: "id",
            //   dataIndex: "id",
            //   key: "id",
            //   width: 120,
            // },
            {
                title: '车架号',
                dataIndex: 'vinNo',
                key: 'vinNo',
                width: 150,
                // slots: {
                //   customRender: "vinNo",
                // },
            },
            {
                title: '车型名称',
                dataIndex: 'modelName',
                key: 'modelName',
                width: 220,
            },
            {
                title: '车款',
                dataIndex: 'modelYear',
                key: 'modelYear',
                width: 120,
            },
            {
                title: '批售日期',
                dataIndex: 'saleDate',
                key: 'saleDate',
                width: 120,
            },
            {
                title: '零售日期',
                dataIndex: 'retailDate',
                key: 'retailDate',
                width: 120,
            },

            {
                title: '金融申请时间',
                dataIndex: 'financialApplicationDate',
                key: 'financialApplicationDate',
                width: 120,
            },
            {
                title: '客户名称',
                dataIndex: 'customerName',
                key: 'customerName',
                width: 220,
            },
            {
                title: '特殊车辆用途',
                dataIndex: 'specialVehicleUse',
                key: 'specialVehicleUse',
                width: 150,
            },
            {
                dataIndex: 'extendedInfo',
                key: 'extendedInfo',
                width: 120,
                slots: {
                    title: 'extendedInfoTitle',
                    customRender: 'extendedInfo',
                },
            },
            {
                // title: categoryName.value,
                dataIndex: 'supportDocVOS',
                key: 'supportDocVOS',
                // width: 120,
                width: supportDocVosLength,
                slots: {
                    title: 'supportDocVOSTitle',
                    customRender: 'supportDocVOS',
                },
            },
            {
                title: '客户政策预估金额',
                dataIndex: 'customerPolicyEstimatedAmount',
                key: 'customerPolicyEstimatedAmount',
                width: 140,
            },
            {
                title: '检查状态',
                dataIndex: 'checkStatus',
                key: 'checkStatus',
                width: 120,
                slots: {
                    customRender: 'checkStatus',
                },
            },
            {
                dataIndex: 'operation',
                key: 'operation',
                width: 130,
                // fixed: 'right',
                slots: {
                    title: 'customTitle',
                    customRender: 'operation',
                },
            },
        ];

        const columnsValue2 = [
            {
                title: '车架号',
                dataIndex: 'vinNo',
                key: 'vinNo',
                width: 150,
            },
            {
                title: '车型名称',
                dataIndex: 'modelName',
                key: 'modelName',
                width: 220,
            },
            {
                title: '车款',
                dataIndex: 'modelYear',
                key: 'modelYear',
                width: 120,
            },
            {
                title: '批售日期',
                dataIndex: 'saleDate',
                key: 'saleDate',
                width: 120,
            },
            {
                title: '零售日期',
                dataIndex: 'retailDate',
                key: 'retailDate',
                width: 120,
            },

            {
                title: '金融申请时间',
                dataIndex: 'financialApplicationDate',
                key: 'financialApplicationDate',
                width: 120,
            },
            {
                title: '客户名称',
                dataIndex: 'customerName',
                key: 'customerName',
                width: 220,
            },
            {
                title: '特殊车辆用途',
                dataIndex: 'specialVehicleUse',
                key: 'specialVehicleUse',
                width: 150,
            },
            {
                dataIndex: 'extendedInfo',
                key: 'extendedInfo',
                width: 120,
                slots: {
                    title: 'extendedInfoTitle',
                    customRender: 'extendedInfo',
                },
            },
            {
                dataIndex: 'supportDocVOS',
                key: 'supportDocVOS',
                width: supportDocVosLength,
                slots: {
                    title: 'supportDocVOSTitle',
                    customRender: 'supportDocVOS',
                },
            },
            {
                title: '检查状态',
                dataIndex: 'checkStatus',
                key: 'checkStatus',
                width: 120,
                slots: {
                    customRender: 'checkStatus',
                },
            },
            {
                dataIndex: 'operation',
                key: 'operation',
                width: 130,
                // fixed: 'right',
                slots: {
                    title: 'customTitle',
                    customRender: 'operation',
                },
            },
        ];

        const columns = ref<any[]>(columnsValue);
        const noStep = ref(false);
        //表格高度
        const tableHeight = computed(() => {
            if (noStep.value) {
                return window.innerHeight - 450;
            }

            if (stepNum.value === 2 || stepNum.value === 1) {
                return window.innerHeight - 535;
            } else {
                return window.innerHeight - 485;
            }
        });
        //多选框参数
        // const checkState = reactive({
        //   indeterminate: false,
        //   checkAll: false,
        // });
        const indeterminate = ref(false);
        const checkAll = ref(false);
        const activeOperation = ref({
            height: '0px',
            width: '0px',
        });

        //用于存储，table中被选中的数组
        const selectTableArray: string[] = new Array<string>();

        //所有可进行申请的条数，应该从后端数据中进行判断，这里只先进行测试先赋值
        const checkAllNum = computed(() => {
            return dataSource.value.filter(
                (item) => item.checkStatus.trim().length <= 0
            ).length;
        });

        //--------事件--------
        //更改表格后面复选框的状态
        const changeSelect = (sign: number) => {
            if (sign === 0) {
                indeterminate.value = false;
                checkAll.value = false;
                activeOperation.value = {
                    height: '0px',
                    width: '0px',
                };
            } else if (sign === 2) {
                indeterminate.value = true;
                checkAll.value = false;
                activeOperation.value = {
                    height: '7px',
                    width: '8px',
                };
            } else if (sign === 1) {
                indeterminate.value = false;
                checkAll.value = true;
                activeOperation.value = {
                    height: '15px',
                    width: '15px',
                };
            }
        };
        //每个多选框的事件
        const checkChange = (item: any) => {
            const id = item.vinNo;
            const index = selectTableArray.indexOf(id);
            if (index > -1) {
                selectTableArray.splice(index, 1);
            } else {
                selectTableArray.push(id);
            }

            if (selectTableArray.length === 0) {
                changeSelect(0);
            } else if (selectTableArray.length === checkAllNum.value) {
                changeSelect(1);
            } else {
                changeSelect(2);
            }
        };
        //多选框全选事件
        const onCheckAllChange = () => {
            //如果列表数量为0，就不能点击
            if (checkAllNum.value === 0) {
                return;
            }
            //将可添加的都放入到selectTableArray中

            if (selectTableArray.length === 0) {
                selectTableArray.splice(0, selectTableArray.length);
                dataSource.value.forEach((item) => {
                    if (item.checkStatus.trim().length <= 0) {
                        item.check = true;
                        selectTableArray.push(item.vinNo);
                    }
                });
                //更改全选框状态
                changeSelect(1);
            } else if (selectTableArray.length < checkAllNum.value) {
                selectTableArray.splice(0, selectTableArray.length);
                dataSource.value.forEach((item) => {
                    if (item.checkStatus.trim().length <= 0) {
                        item.check = true;
                        selectTableArray.push(item.vinNo);
                    }
                });
                changeSelect(1);
            } else {
                selectTableArray.splice(0, selectTableArray.length);
                dataSource.value.forEach((item) => {
                    item.check = false;
                });
                changeSelect(0);
            }
        };

        //同步stepTableArray
        const syncStepTableArray = () => {
            stepTableArray.value = [];

            dataSource.value.forEach((item) =>
                stepTableArray.value.push(item.vinNo)
            );
        };

        //校验2275
        const checkDoc = async () => {
            let filterData = [];
            stepTableArray.value = [];

            //CR新添加
            stepTableArrayDoc.value = [];
            for (let i = 0; i < dataSource.value.length; i++) {
                stepTableArrayDoc.value.push(dataSource.value[i].vinNo);
            }

            //判断是否有选择，获取需要进行校验的数据
            if (selectTableArray.length !== 0) {
                filterData = dataSource.value.filter(
                    (item) => selectTableArray.indexOf(item.vinNo) > -1
                );
            } else {
                filterData = dataSource.value.filter((item) => {
                    return (
                        item.checkStatus.length <= 0 &&
                        item.extendedInfo === '1'
                    );
                });
            }
            if (filterData.length === 0) {
                message.error('没有填写扩展信息的数据，请填写后提交');
                return;
            }

            //判断选择的数据里面有没有没填写扩展信息的
            let sign = 0;
            const signArray = [];
            errorArray = [];
            errorDataSource.value = [];
            for (let i = 0; i < filterData.length; i++) {
                if (filterData[i].extendedInfo === '0') {
                    sign = 1;
                    // if (signArray.length<5){
                    signArray.push(
                        h(
                            'p',
                            '车辆:' +
                                filterData[i].vinNo +
                                '未填写扩展信息，请补充'
                        )
                    );
                    errorArray.push(
                        filterData[i].vinNo + ',' + '未填写扩展信息'
                    );
                    errorDataSource.value.push({
                        VINNo: filterData[i].vinNo,
                        message: '未填写扩展信息',
                    });
                    // }
                }
            }
            //如果有为填写扩展信息的，报错给提示
            // if (sign){
            //   message.error('选择的数据中，有包含未填写扩展信息的条目，请检查')
            //   return false;
            // }

            if (sign) {
                errorModalVisible.value = true;

                // Modal.confirm({
                //         title: '错误信息',
                //         icon: () => h(ExclamationCircleOutlined),
                //         content: h('div', { style: {height: '400px',overflow:'auto'}},  signArray),
                //         width:"500px",
                //         okText:'确定',
                //         cancelText:'导出',
                //         onOk() {
                //             console.log('OK');

                //         },
                //         onCancel() {
                //             console.log('导出信息');
                //             const params = {
                //                 url: `/claimapi/salesIncentiveClaim/export/error/message`,
                //                 method: "post",
                //                 params: errorArray,
                //                 fileName: `模板.xlsx`,
                //             };
                //             downloadExtendInfoApi(params)
                //         },
                //     });
                return false;
            }

            //进行后端校验
            const vinNoArray: Array<string> = [];

            filterData.forEach((item) => {
                vinNoArray.push(item.vinNo);
            });

            const param = {
                dealerCode: option.value.dealerCode,
                offerTypeId: option.value.offerTypeId,
                progDesignId: option.value.programId,
                vinNos: vinNoArray,
            };
            let checkSign = false;
            const checkArray: Array<any> = [];

            await validateReplaceAttributes(param).then((res: any): void => {
                if (res.length > 0) {
                    for (let i = 0; i < res.length; i++) {
                        if (checkArray.length < 5) {
                            checkArray.push(
                                h('p', res[i].vinNo + ',' + res[i].message)
                            );
                        }
                    }
                    checkSign = true;
                }
            });
            if (checkSign) {
                Modal.error({
                    title: '错误信息',
                    content: h('div', {}, checkArray),
                });

                return false;
            }

            //重置所有数据的复选框状态，并保存到底去下一步有多少数据
            filterData.forEach((item) => {
                item.check = false;
                stepTableArray.value.push(item.vinNo);
            });
            //对表格重新赋值
            //重置复选框功能，并清空保存数组
            dataSource.value = filterData;
            selectTableArray.splice(0, selectTableArray.length);
            changeSelect(0);

            return true;
        };
        //校验2276
        const checkSupport = () => {
            stepTableArraySupport.value = [];
            for (let i = 0; i < dataSource.value.length; i++) {
                stepTableArraySupport.value.push(dataSource.value[i].vinNo);
            }
            let filterData = [];
            //判断是否有选择
            if (selectTableArray.length !== 0) {
                filterData = dataSource.value.filter(
                    (item) => selectTableArray.indexOf(item.vinNo) > -1
                );
            } else {
                filterData = dataSource.value.filter(
                    (item) => item.checkStatus.length <= 0
                );
            }
            if (filterData.length === 0) {
                message.error('没有可以提交的数据');
                return false;
            }

            //对必填项进行校验
            let support = false;
            errorArray = [];
            const supportArray = [];
            errorDataSource.value = [];
            for (let i = 0; i < filterData.length; i++) {
                const one = filterData[i];
                for (let j = 0; j < one.supportDocVOS.length; j++) {
                    if (one.supportDocVOS[j].isRequired === 'Y') {
                        if (!one.supportDocVOS[j].supportDocFilePath) {
                            // if (one.supportDocVOS[j].supportDocFilePath!==null && one.supportDocVOS[j].supportDocFilePath.trim().length<=0){
                            // message.error('有必须填写的支持文档没有上传，不能进行下一步操作')
                            support = true;
                            // if (supportArray.length<5){
                            errorArray.push(
                                filterData[i].vinNo +
                                    ',' +
                                    one.supportDocVOS[j].supportDocName +
                                    '文档未上传'
                            );
                            errorDataSource.value.push({
                                VINNo: filterData[i].vinNo,
                                message:
                                    one.supportDocVOS[j].supportDocName +
                                    '文档未上传',
                            });
                            supportArray.push(
                                h(
                                    'p',
                                    one.vinNo +
                                        ',' +
                                        one.supportDocVOS[j].supportDocName +
                                        '的文档未上传'
                                )
                            );
                            // }
                        }
                    }
                }
            }

            if (support) {
                errorModalVisible.value = true;
                // Modal.confirm({
                //     title: '错误信息',
                //     icon: () => h(ExclamationCircleOutlined),
                //     width:"500px",
                //     content: h('div', { style: {height: '400px',overflow:'auto'}}, supportArray),
                //     okText:'确定',
                //     cancelText:'导出',
                //     onOk() {
                //         console.log('OK');

                //     },
                //     onCancel() {
                //         console.log('导出信息');
                //         const params = {
                //             url: `/claimapi/salesIncentiveClaim/export/error/message`,
                //             method: "post",
                //             params: errorArray,
                //             fileName: `模板.xlsx`,
                //         };
                //         downloadExtendInfoApi(params)
                //     },
                // });

                return false;
            } else {
                dataSource.value = filterData;
                return true;
            }
        };
        //跳转到扩展信息
        const goDoc = () => {
            extendActive.value = true;
            extendActiveTitle.value = true;
            //更改supportActive状态
            supportActive.value = false;
            supportActiveTitle.value = false;

            stepNum.value = 1;
            nextButtonText.value = '下一步';
            currentStep.value = 0;
        };

        //跳转到支持文档
        const goSupport = () => {
            docSample({ programId: option.value.programId }).then(
                (res: any): void => {
                    if (res.length > 0) {
                        supportSample.value = res;
                    }
                    //所有数据设置为全选状态
                    // changeSelect(1);
                    //进入到一步
                    stepNum.value = 2;
                    nextButtonText.value = '下一步';
                    //更改css状态，更改step状态

                    for (let i = 0; i < stepArray.value.length; i++) {
                        if (stepArray.value[i] === '提交支持文档') {
                            currentStep.value = i;
                        }
                    }
                    //全部变亮
                    // dataSource.value.forEach((item) => {
                    //     if (item.checkStatus.length === 0){
                    //         item.check = true;
                    //         // stepTableArray.value.push(item.vinNo)
                    //         selectTableArray.push(item.vinNo)
                    //     }

                    // });
                    dataCount.value = dataSource.value.length;

                    extendActive.value = false;
                    extendActiveTitle.value = false;
                    //更改supportActive状态
                    supportActive.value = true;
                    supportActiveTitle.value = true;
                }
            );
        };
        //跳转到提交
        const goSubmit = () => {
            nextButtonText.value = '提交';
            stepNum.value = 3;
            // stepTableArray.value = [];

            changeSelect(0);

            dataSource.value.forEach((item) => {
                item.check = false;
                // stepTableArray.value.push(item.vinNo)
            });
            changeSelect(0);
            //更改css状态，更改步骤条
            for (let i = 0; i < stepArray.value.length; i++) {
                if (stepArray.value[i] === '提交') {
                    currentStep.value = i;
                }
            }
            selectTableArray.splice(0, selectTableArray.length);

            dataCount.value = dataSource.value.length;
            supportActive.value = true;
            extendActive.value = true;
            extendActiveTitle.value = false;
            supportActiveTitle.value = false;
        };

        const claimDocumentMatching = ref<any>({});
        let VINarray: Array<string> = [];
        //提交数据
        const onSubmit = () => {
            const parmas = [];
            VINarray = [];
            let filterData = [];
            if (selectTableArray.length !== 0) {
                filterData = dataSource.value.filter(
                    (item) => selectTableArray.indexOf(item.vinNo) > -1
                );
            } else {
                filterData = dataSource.value;
            }
            filterData = filterData.filter(
                (item) => item.checkStatus.trim().length <= 0
            );

            for (let i = 0; i < filterData.length; i++) {
                const doc = filterData[i];

                const supportDocArray = [];

                if (Array.isArray(doc.supportDocVOS)) {
                    for (let j = 0; j < doc.supportDocVOS.length; j++) {
                        supportDocArray.push(doc.supportDocVOS[j].id);
                    }
                }

                const param = {
                    //新增的，offerTypeNameEn，programNameCn，其中programNameCn还没有，先传空
                    offerTypeNameEn: option.value.offerTypeNameEn,
                    programNameCn: doc.programNameCn,
                    customerName: doc.customerName,
                    dealerCode: option.value.dealerCode,
                    dealerNameCn: option.value.dealerNameCn,
                    eligiblePeriodFrom:
                        option.value.retailBegin === ''
                            ? ''
                            : moment(option.value.retailBegin).format(
                                  'YYYY-MM-DD'
                              ),
                    eligiblePeriodTo:
                        option.value.retailEnd === ''
                            ? ''
                            : moment(option.value.retailEnd).format(
                                  'YYYY-MM-DD'
                              ),
                    eligiblePeriodType: option.value.eligiblePeriodName, //事成
                    eligiblePeriodId: option.value.eligiblePeriodId, //事成
                    // firstSubmitDate: doc.financialApplicationDate ==="" ? "": moment(doc.financialApplicationDate).format( "YYYY-MM-DD"),
                    id: '',
                    inParagraph: doc.modelYear,
                    incentiveCategory: option.value.incentiveCategory,
                    model: doc.modelName,
                    offerTypeId: option.value.offerTypeId,
                    offerTypeName: option.value.categoryName,
                    predictAmount: doc.customerPolicyEstimatedAmount,
                    price: '',
                    progDesignId: option.value.programId,
                    programCode: doc.programCode,
                    programName: doc.programName,
                    retailDate:
                        doc.retailDate === '' || doc.retailDate === null
                            ? ''
                            : moment(doc.retailDate).format('YYYY-MM-DD'),
                    specialUsage: doc.specialVehicleUse,
                    status: '',
                    submissionDateFrom:
                        option.value.submissionDateFrom === ''
                            ? ''
                            : moment(option.value.submissionDateFrom).format(
                                  'YYYY-MM-DD'
                              ),
                    submissionDateTo:
                        option.value.submissionDateTo === ''
                            ? ''
                            : moment(option.value.submissionDateTo).format(
                                  'YYYY-MM-DD'
                              ),
                    typeClass: doc.typeClass,
                    vinNo: doc.vinNo,
                    wholesaleDate:
                        doc.saleDate === ''
                            ? ''
                            : moment(doc.saleDate).format('YYYY-MM-DD'),
                    supportDocIds: supportDocArray,
                    commissionNo: doc.commissionNo,
                    engineNo: doc.engineNo,
                    entity: doc.entity,
                    fin: doc.fin,
                    materialNo: doc.materialNo,
                    programOwner: doc.programCreator,
                    releaseDate: doc.programReleaseDate,
                    supportOfferId: doc.supportOfferId,
                    supportOfferName: doc.supportOfferName,
                    dealerNameEn: option.value.dealerName,
                    offerTypePathKey: option.value.offerTypeKey,
                    // programBuSecond:doc.programBuSecond,
                    // bu:doc.programBu,
                    programBuId:
                        option.value.programBu === 'null'
                            ? ''
                            : option.value.programBu,
                    bu:
                        option.value.programBuName === 'null'
                            ? ''
                            : option.value.programBuName,
                    programBuSecondId:
                        option.value.programBuSecond === 'null'
                            ? ''
                            : option.value.programBuSecond,
                    programBuSecond:
                        option.value.programBuSecondName === 'null'
                            ? ''
                            : option.value.programBuSecondName,
                    make: doc.make,
                    siOffer: doc.siOffer,
                    conflict: doc.conflict,
                    orderProtection: doc.orderProtection,
                    presale: doc.presale,
                };
                VINarray.push(doc.vinNo);
                parmas.push(param);
            }

            claimDocumentMatching.value = {
                dealerCode: option.value.dealerCode,
                offerTypeId: option.value.offerTypeId,
                programId: option.value.programId,
                vinNos: VINarray,
            };

            saveDealerClaimApp(parmas).then((res: any): void => {
                if (res.length !== 0) {
                    if (res[0].checkStatus === '提交成功') {
                        setClaimDocumentMatching(
                            claimDocumentMatching.value
                        ).then((res: any): void => {
                            console.log('保存成功');
                        });
                        message.success('提交成功，即将跳转');
                        setTimeout(() => {
                            router.push({ name: 'Sales Incentive Claim' });
                        }, 1000);
                    } else {
                        for (let i = 0; i < res.length; i++) {
                            for (let j = 0; j < dataSource.value.length; j++) {
                                if (
                                    res[i].vinNo === dataSource.value[j].vinNo
                                ) {
                                    dataSource.value[j].checkStatus =
                                        res[i].checkStatus;
                                    if (selectTableArray.length !== 0) {
                                        const index = selectTableArray.indexOf(
                                            res[i].vinNo
                                        );
                                        selectTableArray.splice(index, 1);
                                    }
                                    continue;
                                }
                            }
                        }
                        message.error(
                            '校验数据中存在错误，请取消选择后重新提交'
                        );
                    }
                }
            });
        };

        //CR添加,单纯获取数据，并根据传入参数，进行对表格数据复原
        const getTableDataCheckArray = (array: Array<string> | null) => {
            const param = {
                dealerCode: option.value.dealerCode,
                modelName: screeningConditionsTemporary.modelName,
                modelYear: '',
                offerTypeKey: option.value.offerTypePathKey,
                // offerTypeKey:"Overall",
                programId: option.value.programId,
                retailBegin: '',
                retailEnd: '',
                supportType: option.value.offerTypeId,
                vin: screeningConditionsTemporary.vinNo,
                wholesaleBegin: '',
                wholesaleEnd: '',
            };

            if (screeningConditionsTemporary.retailDate.length > 0) {
                param.retailBegin = screeningConditionsTemporary.retailDate[0];
                param.retailEnd = screeningConditionsTemporary.retailDate[1];
            } else {
                if (option.value.projectType === '零售日期') {
                    param.retailBegin = option.value.retailBegin;
                    param.retailEnd = option.value.retailEnd;
                }
            }

            if (screeningConditionsTemporary.saleDate.length > 0) {
                param.wholesaleBegin = screeningConditionsTemporary.saleDate[0];
                param.wholesaleEnd = screeningConditionsTemporary.saleDate[1];
            } else {
                if (option.value.projectType === '批售日期') {
                    param.wholesaleBegin = option.value.submissionDateFrom;
                    param.wholesaleEnd = option.value.submissionDateTo;
                }
            }

            getVINTableData(param).then((data: any): void => {
                console.log(data);
                const scoreMap = data.reduce((obj: any, cur: any) => {
                    obj[cur.vinNo] = cur;
                    return obj;
                }, Object.create(null));
                const oldArray = [];
                stepTableArray.value = [];
                if (array && array.length > 0 && array.length !== data.length) {
                    for (let i = 0; i < array.length; i++) {
                        oldArray.push(scoreMap[array[i]]);
                        stepTableArray.value.push(array[i]);
                    }
                    dataSource.value = oldArray;
                } else {
                    dataSource.value = data;
                }
                dataCount.value = dataSource.value.length;
            });
        };
        const selectTabs = ref(0);
        const preStep = () => {
            //上一步按钮

            // stepNum.value = 1;
            // currentStep.value = 0;
            console.log(currentStep.value);
            if (currentStep.value === 0 || stepArray.value.length === 0) {
                //需要跳转到2797
                ctx.emit('pre-page', selectTabs.value);
            } else {
                //跳转到上一步
                const preName = stepArray.value[currentStep.value - 1];
                debugger;
                let array = null;
                if (preName === '填写扩展信息') {
                    goDoc();
                } else {
                    goSupport();
                    array = stepTableArraySupport.value;
                }

                getTableDataCheckArray(array);
            }
        };

        const nextStep = async () => {
            // debugger
            //从2275 -> 2276
            if (stepArray.value.length === 3 && currentStep.value === 0) {
                const sign = await checkDoc();

                if (sign) {
                    syncStepTableArray();
                    goSupport();
                }
                return;
            }
            //从2276 -> 2277
            if (stepArray.value.length === 3 && currentStep.value === 1) {
                const sign = checkSupport();
                if (sign) {
                    syncStepTableArray();
                    nextButtonText.value = '提交';
                    goSubmit();
                }

                return;
            }
            //从 2275 - > 2277 ,2276 -> 2277
            if (stepArray.value.length === 2 && currentStep.value === 0) {
                if (stepArray.value[0] === '填写扩展信息') {
                    const sign = await checkDoc();

                    if (sign) {
                        syncStepTableArray();
                        goSubmit();
                    }
                } else {
                    const sign = checkSupport();
                    if (sign) {
                        syncStepTableArray();
                        nextButtonText.value = '提交';
                        goSubmit();
                    }
                }

                return;
            }
            //2277 -> 提交功能
            if (stepArray.value.length === 3 && currentStep.value === 2) {
                //提交
                onSubmit();
            }
            //2277 -> 提交功能
            if (stepArray.value.length === 2 && currentStep.value === 1) {
                //提交
                onSubmit();
            }
            //2277 -> 提交功能
            if (stepArray.value.length === 1 && currentStep.value === 0) {
                //提交
                onSubmit();
            }
            if (stepArray.value.length === 0) {
                //提交
                onSubmit();
            }
        };

        //重置查询条件方法
        const clearScreeningConditions = () => {
            screeningConditions.vinNo = null;
            screeningConditions.modelName = null;
            screeningConditions.retailDate = [];
            screeningConditions.saleDate = [];
            screeningConditions.vinNoList = [];

            screeningConditionsTemporary.vinNo = null;
            screeningConditionsTemporary.modelName = null;
            screeningConditionsTemporary.retailDate = [];
            screeningConditionsTemporary.saleDate = [];
            screeningConditionsTemporary.vinNoList = [];
        };

        let hasDoc = false;
        let hasSupport = false;

        //获取表格数据
        const getTableData = () => {
            supportDocSign.value = false;
            hasDoc = false;
            hasSupport = false;
            // screeningConditionsTemporary
            screeningConditionsTemporary.vinNo = screeningConditions.vinNo;
            screeningConditionsTemporary.modelName =
                screeningConditions.modelName;
            screeningConditionsTemporary.retailDate =
                screeningConditions.retailDate;
            screeningConditionsTemporary.saleDate =
                screeningConditions.saleDate;
            screeningConditionsTemporary.vinNoList =
                screeningConditions.vinNoList;

            // let retailBeginT = "";
            // let retailEndT = "";
            // let wholesaleBegiT = "";
            // let wholesaleEndT = "";
            // if (screeningConditionsTemporary.retailDate === []){
            //     retailBeginT = screeningConditionsTemporary.retailDate[0];
            //     retailEndT = screeningConditionsTemporary.retailDate[1];
            // } else {
            //     // retailBeginT = option.value.startTimeRetail;
            //     // retailEndT = option.value.endTimeRetail;
            //     retailBeginT = "";
            //     retailEndT = "";
            // }

            // if (screeningConditionsTemporary.saleDate === []){
            //     wholesaleBegiT = screeningConditionsTemporary.saleDate[0];
            //     wholesaleEndT = screeningConditionsTemporary.saleDate[1];
            // } else {
            //     wholesaleBegiT = "";
            //     wholesaleEndT = "";
            // }

            // const param = {
            //     supportType: option.value.offerTypeId,
            //     programId: option.value.programId,
            //     retailBegin: retailBeginT,
            //     retailEnd: retailEndT,
            //     dealerCode: option.value.dealerCode,
            //     vin: screeningConditionsTemporary.vinNo,
            //     wholesaleBegin: wholesaleBegiT,
            //     wholesaleEnd: wholesaleEndT
            // };

            // option.value.dealerCode = "193120320";
            // option.value.programId ="6f12d388-79d8-4c48-a9be-dad0d7a9ef0e"
            // option.value.offerTypeKey = "overall"
            const param = {
                dealerCode: option.value.dealerCode,
                modelName: screeningConditionsTemporary.modelName,
                modelYear: '',
                offerTypeKey: option.value.offerTypePathKey,
                // offerTypeKey:"Overall",
                programId: option.value.programId,
                retailBegin: '',
                retailEnd: '',
                supportType: option.value.offerTypeId,
                vin:
                    screeningConditionsTemporary.vinNoList.length >= 1 &&
                    screeningConditionsTemporary.vinNoList[0] !== ''
                        ? ''
                        : screeningConditionsTemporary.vinNo,
                vinNoList:
                    screeningConditionsTemporary.vinNoList.length === 1 &&
                    screeningConditionsTemporary.vinNoList[0] === ''
                        ? []
                        : screeningConditionsTemporary.vinNoList,
                wholesaleBegin: '',
                wholesaleEnd: '',
            };

            if (screeningConditionsTemporary.retailDate.length > 0) {
                param.retailBegin = screeningConditionsTemporary.retailDate[0];
                param.retailEnd = screeningConditionsTemporary.retailDate[1];
            } else {
                if (option.value.projectType === '零售日期') {
                    param.retailBegin = option.value.retailBegin;
                    param.retailEnd = option.value.retailEnd;
                }
            }

            if (screeningConditionsTemporary.saleDate.length > 0) {
                param.wholesaleBegin = screeningConditionsTemporary.saleDate[0];
                param.wholesaleEnd = screeningConditionsTemporary.saleDate[1];
            } else {
                if (option.value.projectType === '批售日期') {
                    param.wholesaleBegin = option.value.submissionDateFrom;
                    param.wholesaleEnd = option.value.submissionDateTo;
                }
            }

            getVINTableData(param).then((data: any): void => {
                if (data.length === 0) {
                    message.error('未查询到数据');
                    dataSource.value = [];
                    return;
                }

                // if (isFirst){
                //     scoreMap.value = data.reduce((obj: any, cur: any) => {
                //         obj[cur.vinNo] = cur;
                //         return obj;
                //     }, Object.create(null));
                //     console.log(scoreMap.value)
                //     isFirst = false;
                // }

                //用于筛选每次步骤剩余的table的条目
                if (stepTableArray.value.length !== 0) {
                    const scoreMap = data.reduce((obj: any, cur: any) => {
                        obj[cur.vinNo] = cur;
                        return obj;
                    }, Object.create(null));
                    const temporary = [];
                    for (let i = 0; i < stepTableArray.value.length; i++) {
                        if (scoreMap[stepTableArray.value[i]]) {
                            temporary.push(scoreMap[stepTableArray.value[i]]);
                        }
                    }

                    dataSource.value = temporary;
                } else {
                    dataSource.value = data;
                }

                dataCount.value = dataSource.value.length;

                //用于判断支持文档的长度
                for (let i = 0; i < dataSource.value.length; i++) {
                    const data = dataSource.value[i];
                    let strLength = 0;
                    for (let j = 0; j < data.supportDocVOS.length; j++) {
                        strLength =
                            strLength +
                            data.supportDocVOS[j].supportDocName.length +
                            2;
                        supportDocSign.value = true;
                    }

                    if (strLength * 15 > supportDocVosLength.value) {
                        supportDocVosLength.value = strLength * 15;
                    }
                }
                //步骤条
                stepArray.value = [];

                //判断是否显示填写扩展信息
                if (
                    data.length > 0 &&
                    data[0].extendedInfo &&
                    data[0].extendedInfo.length > 0
                ) {
                    stepArray.value.push('填写扩展信息');
                    hasDoc = true;
                }
                //判断是否显示提交支持文档
                if (supportDocSign.value) {
                    stepArray.value.push('提交支持文档');
                    hasSupport = true;
                }

                //添加提交
                if (
                    (data.length > 0 &&
                        data[0].extendedInfo &&
                        data[0].extendedInfo.length > 0) ||
                    supportDocSign.value
                ) {
                    stepArray.value.push('提交');
                } else {
                    noStep.value = true;
                }
                //重置复选框
                selectTableArray.splice(0, selectTableArray.length);
                changeSelect(0);

                //对没有扩展信息和支持文档的情况，之际进行跳转
                if (!hasDoc) {
                    if (!hasSupport) {
                        goSubmit();
                        return;
                    } else {
                        goSupport();
                        return;
                    }
                }
            });
        };

        const modalColumns = ref<any[]>([
            {
                title: '项目编号',
                dataIndex: 'programeCode',
                key: 'programeCode',
                width: 150,
            },
            {
                title: '项目类型',
                dataIndex: 'categoryName',
                key: 'categoryName',
                width: 170,
            },
            {
                title: '客户政策预估金额',
                dataIndex: 'customerPolicyEstimatedAmount',
                key: 'customerPolicyEstimatedAmount',
                width: 170,
            },
            {
                title: '状态',
                dataIndex: 'checkStatus',
                key: 'checkStatus',
                width: 170,
            },
        ]);

        // 一级BU是否为CPO
        const isCPO = ref(false)

        //初始化事件
        const init = (item: any, select: number) => {
            selectTabs.value = select;
            option.value = item;
            categoryName.value = option.value.categoryName;

            getBuTreeList().then(buTree => {
                const firstLevelBU = getFirstLevelBU(buTree as any as BuTreeProps[], option.value.programBuName)
                if (firstLevelBU?.nameEn === 'CPO') {
                    isCPO.value = true
                }
            })

            if (option.value.incentiveCategory !== 'Customer Incentive') {
                columns.value = columnsValue2;
                modalColumns.value = [
                    {
                        title: '项目编号',
                        dataIndex: 'programeCode',
                        key: 'programeCode',
                        width: 150,
                    },
                    {
                        title: '项目类型',
                        dataIndex: 'categoryName',
                        key: 'categoryName',
                        width: 170,
                    },
                    {
                        title: '状态',
                        dataIndex: 'checkStatus',
                        key: 'checkStatus',
                        width: 170,
                    },
                ];
            }

            getTableData();
        };

        //下载扩展信息文档
        const downloadExtendInfo = () => {
            const vinClaimConflict: any[] = [];

            if (selectTableArray.length === 0) {
                dataSource.value.forEach((item) => {
                    if (item.checkStatus.trim().length <= 0) {
                        vinClaimConflict.push({
                            conflict: item.conflict,
                            vinNo: item.vinNo,
                        });
                    }
                });
            } else {
                // vinNoArray = vinNoArray.concat(selectTableArray);
                for (let i = 0; i < selectTableArray.length; i++) {
                    const vinNo = selectTableArray[i];
                    for (let j = 0; j < dataSource.value.length; j++) {
                        if (vinNo === dataSource.value[j].vinNo) {
                            vinClaimConflict.push({
                                conflict: dataSource.value[j].conflict,
                                vinNo: dataSource.value[j].vinNo,
                            });
                            continue;
                        }
                    }
                }
            }
            const downloadParams = {
                progId: option.value.programId,
                vinClaimConflict: vinClaimConflict,
                offerTypeId: option.value.categoryID,
                dealerCode: option.value.dealerCode,
                offerTypeKey: option.value.offerTypeKey,
            };

            const params = {
                url: `/claimapi/dealer/extend/templateDownload`,
                method: 'post',
                params: downloadParams,
                fileName: `模板.xlsx`,
            };
            downloadExtendInfoApi(params);
        };

        //显示填写扩展信息的抽屉
        const showFill = (item: DealerVINList) => {
            if (extendActive.value === false) {
                return;
            }

            let sign = true;
            if (extendActiveTitle.value === true) {
                sign = false;
            }

            if (item.checkStatus === '已申报') {
                sign = true;
            }

            extendInfoDom.value.editExtend(
                option.value.programId,
                item,
                option.value.offerTypeId,
                sign,
                option.value.dealerCode
            );
        };
        //显示批量上传扩展信息抽屉
        const showUploadDrawer = () => {
            uploadInfoDom.value.showDrawer({
                prodId: option.value.programId,
                offerTypeId: option.value.offerTypeId,
                offerTypeKey: option.value.offerTypeKey,
                dealerCode: option.value.dealerCode,
            });
        };
        //显示模板样例
        const showExample = (item: any) => {
            supportExample.value.showDrawer(item);
        };
        //根据参数显示上传文档抽屉
        const showUploadSupport = (
            data: any,
            message: any,
            isMultiple: boolean,
            isOnlyRead: boolean
        ) => {
            let title = '上传文档';
            if (isOnlyRead) {
                title = '文档预览';
            }

            // const vinNos = ref<any[]>([]);
            // if (stepTableArray.value.length !==  0){
            //     vinNos.value = stepTableArray.value;
            // } else {
            //     for (let i=0;i<dataSource.value.length;i++){
            //         vinNos.value.push(dataSource.value[i].vinNo)
            //     }
            // }
            // console.log(vinNos)
            // debugger

            const param = {
                title: title,
                data: data,
                isMultiple: isMultiple,
                isOnlyRead: isOnlyRead,
                message: message,
                stepTableArray: [],
            };
            uploadSupportDom.value.showDrawer(param);
        };

        //上传单个支持文档
        const uploadSingleSupport = (
            record: any,
            index: number,
            isOnlyRead: boolean
        ) => {
            const param = {
                vinNo: record.vinNo,
                programId: option.value.programId,
                offerTypeId: option.value.offerTypeId,
                dealerCode: option.value.dealerCode,
                offerTypeKey: option.value.offerTypeKey,
                index: index,
            };

            supportReview(param).then((data: any): void => {
                //返回的数据，各类必须参数，是否是多选，是否只读，

                const filterData: Array<any> = [];

                for (let i = 0; i < data.length; i++) {
                    if (data[i].tabName.length > 0) {
                        filterData.push(data[i]);
                    }
                }

                const obj = Object.assign(
                    {
                        retailBegin: option.value.retailBegin,
                        endTimeRetail: option.value.retailEnd,
                    },
                    param
                );

                showUploadSupport(filterData, obj, false, isOnlyRead);
            });
        };

        //预览上传文档
        const showSupportPic = (record: any, index: number) => {
            if (
                supportActive.value === true &&
                record.supportDocVOS[index].supportDocFilePath !== null &&
                record.supportDocVOS[index].supportDocFilePath.length > 0
            ) {
                uploadSingleSupport(record, index, true);
            }
        };

        //批量上传支持文档
        const showUploadSupportDrawer = () => {
            const param = {
                vinNo: '',
                programId: option.value.programId,
                offerTypeId: option.value.offerTypeId,
                dealerCode: option.value.dealerCode,
                offerTypeKey: option.value.offerTypeKey,
                index: 0,
            };
            supportReview(param).then((data: any): void => {
                const obj = Object.assign(
                    {
                        retailBegin: option.value.retailBegin,
                        endTimeRetail: option.value.retailEnd,
                    },
                    param
                );

                showUploadSupport(data, obj, true, false);
            });
        };

        const modalVisible = ref(false);
        const modalTitle = ref('');
        const modalDataSource = ref<any[]>([]);

        //显示检查状态
        const showCheckStatus = (record: any) => {
            if (
                record.checkStatus === '兼容性校验不通过' &&
                record.declarationIncompatibleList.length > 0
            ) {
                // if (record.checkStatus !== '兼容性校验不通过'){
                modalVisible.value = true;
                modalTitle.value = '车架号：' + record.vinNo;
                modalDataSource.value = [
                    {
                        checkStatus:
                            record.declarationIncompatibleList[0].status,
                        customerPolicyEstimatedAmount:
                            record.declarationIncompatibleList[0]
                                .customerPolicyEstimatedAmount,
                        programeCode:
                            record.declarationIncompatibleList[0].programCode,
                        categoryName:
                            record.declarationIncompatibleList[0]
                                .offerTypeNameCn,
                    },
                ];
            }
        };

        onMounted(() => {
            // stepArray.value = ['步骤1','步骤2','步骤3']
        });

        const disabledWholelDate = (current: Moment) => {
            if (option.value.projectType !== '零售日期') {
                return (
                    current < moment(option.value.retailBegin + ' 00:00:00') ||
                    current > moment(option.value.retailEnd + ' 23:59:59')
                );
            } else {
                return false;
            }
        };

        const disabledRetailDate = (current: Moment) => {
            if (option.value.projectType === '零售日期') {
                return (
                    current < moment(option.value.retailBegin + ' 00:00:00') ||
                    current > moment(option.value.retailEnd + ' 23:59:59')
                );
            } else {
                return false;
            }
        };
        const exportTable = () => {
            const array = [];
            for (let i = 0; i < dataSource.value.length; i++) {
                array.push(dataSource.value[i].vinNo);
            }
            const param = {
                dealerCode: option.value.dealerCode,
                modelName: screeningConditionsTemporary.modelName,
                modelYear: '',
                offerTypeKey: option.value.offerTypePathKey,
                programId: option.value.programId,
                retailBegin: '',
                retailEnd: '',
                supportType: option.value.offerTypeId,
                vin: '',
                vinNoList: array,
                wholesaleBegin: '',
                wholesaleEnd: '',
            };

            // const param ={
            //     dealerCode: option.value.dealerCode,
            //     modelName: screeningConditionsTemporary.modelName,
            //     modelYear: "",
            //     offerTypeKey: option.value.offerTypePathKey,
            //     programId:  option.value.programId,
            //     retailBegin: "",
            //     retailEnd: "",
            //     supportType: option.value.offerTypeId,
            //     vin: (screeningConditionsTemporary.vinNoList.length>=1 && (screeningConditionsTemporary.vinNoList[0] !== ""))?'':screeningConditionsTemporary.vinNo,
            //     vinNoList: (screeningConditionsTemporary.vinNoList.length === 1 && (screeningConditionsTemporary.vinNoList[0] === ""))?[]:screeningConditionsTemporary.vinNoList,
            //     wholesaleBegin: "",
            //     wholesaleEnd: ""
            // }
            // if (screeningConditionsTemporary.retailDate.length>0){
            //     param.retailBegin = screeningConditionsTemporary.retailDate[0];
            //     param.retailEnd = screeningConditionsTemporary.retailDate[1];
            // } else {
            //     if (option.value.projectType === '零售日期'){
            //         param.retailBegin =  option.value.retailBegin;
            //         param.retailEnd =  option.value.retailEnd;
            //     }
            // }

            // if (screeningConditionsTemporary.saleDate.length>0){
            //     param.wholesaleBegin = screeningConditionsTemporary.saleDate[0];
            //     param.wholesaleEnd = screeningConditionsTemporary.saleDate[1];

            // } else {
            //     if (option.value.projectType === '批售日期'){
            //         param.wholesaleBegin =  option.value.submissionDateFrom;
            //         param.wholesaleEnd =  option.value.submissionDateTo;
            //     }
            // }

            // console.log(param)
            const par = {
                url: `/claimapi/salesIncentiveClaim/export`,
                method: 'post',
                params: param,
            };
            downloadFile(par, 'application/vnd-excel; char-set=UTF-8').then(
                (res) => {
                    console.log(res);
                }
            );
        };
        // 批量查询
        const batchQuery = reactive({
            batchQueryTitle: 'VIN码查询',
            batchQueryModal: false,
            batchQueryListTitle: 'VIN码列表',
        });
        //Modal的输入框
        const textContentNo = ref<string>('');
        //输入框，用于控制焦点
        const input = ref();
        //显示的VIN数组
        const textNoArray = ref<string[]>([]);
        //空占位
        const batchPlaceholder = ref(['', '', '', '', '', '']);
        const cleanEmptyEleArray = (array: Array<string>) => {
            if (array.length > 1) {
                const tempArray = array.filter((item) => item !== '');
                tempArray.length || tempArray.push('');
                return tempArray;
            } else {
                return array;
            }
        };
        // 关闭批量查询Modal
        const closeBatchQueryModal = () => {
            screeningConditions.vinNo = cleanEmptyEleArray(
                screeningConditions.vinNoList
            )[0] as string;
        };
        // 设置批量查询modal 的 占位div
        const setBatchPlaceholder = () => {
            if (textNoArray.value.length) {
                batchPlaceholder.value = [];
            } else {
                batchPlaceholder.value = ['', '', '', '', '', ''];
            }
        };
        // 批量文本内容
        const textChangEvent = () => {
            if (textContentNo.value.length < 5) {
                message.error('请从Excel中粘贴复制查询');
                textContentNo.value = '';
                return;
            }
            textNoArray.value = textNoArray.value.concat(
                textContentNo.value.replace(/\r|\n|\s/g, ',').split(',')
            );
            const dom = document.getElementsByClassName('list-content')[0];
            textContentNo.value = '';
            screeningConditions.vinNoList = [];
            screeningConditions.vinNoList = screeningConditions.vinNoList.concat(
                textNoArray.value
            );
            setBatchPlaceholder();
            nextTick(() => {
                dom.scrollTo(0, dom.scrollHeight + 40);
                input.value.focus();
            });
        };

        // 批量查询按钮事件
        const batchQueryEvent = () => {
            batchQuery.batchQueryModal = true;
            textNoArray.value =
                cleanEmptyEleArray(screeningConditions.vinNoList)[0] === ''
                    ? []
                    : cleanEmptyEleArray(screeningConditions.vinNoList);
            setBatchPlaceholder();
            const dom = document.getElementsByClassName('list-content')[0];
            nextTick(() => {
                dom && dom.scrollTo(0, textNoArray.value.length * 36);
                input.value.focus();
            });
        };
        // 清除批量查询
        const clearBatchQuery = () => {
            screeningConditions.vinNoList = [''];
            textNoArray.value =
                cleanEmptyEleArray(screeningConditions.vinNoList)[0] === ''
                    ? []
                    : cleanEmptyEleArray(screeningConditions.vinNoList);
            setBatchPlaceholder();
            screeningConditions.vinNo = null;
            input.value.focus();
        };

        const exportError = () => {
            const params = {
                url: `/claimapi/salesIncentiveClaim/export/error/message`,
                method: 'post',
                params: errorArray,
                fileName: `模板.xlsx`,
            };
            downloadExtendInfoApi(params);
            errorModalVisible.value = false;
        };

        const jumpToUploadUsedCarPage = () => {
            Modal.confirm({
                title: h('span', {style: {fontWeight: 'bold', color: '#838383'}}, '页面跳转'),
                content: h('p', {}, [
                    '置换及CPO销售的支持文档需至',
                    h('span', {style: {color: '#00ADEF'}}, '二手车文件模块'),
                    '进行提交，请确认是否跳转至该模块?'
                  ]
                ),
                okText: '确认',
                cancelText: '取消',
                onOk() {
                    window.open('/#/UploadUsedCarPage?type=1', '_blank')
                }
            });
        }

        return {
            errorModalVisible,
            exportError,
            errorDataSource,
            errorColumns,
            clearBatchQuery,
            batchQueryEvent,
            textContentNo,
            input,
            closeBatchQueryModal,
            ...toRefs(batchQuery),
            textNoArray,
            textChangEvent,
            batchPlaceholder,
            dataCount,
            preStep,
            exportTable,
            disabledRetailDate,
            disabledWholelDate,
            modalVisible,
            modalTitle,
            modalDataSource,
            modalColumns,
            extendInfoDom,
            uploadInfoDom,
            supportExample,
            showUploadSupportDrawer,
            uploadSupportDom,
            showCheckStatus,
            extendActive,
            extendActiveTitle,
            supportActive,
            supportActiveTitle,
            supportSample,
            categoryName,
            nextButtonText,
            screeningConditions,
            currentStep,
            stepArray,
            stepNum,
            tableHeight,
            dataSource,
            columns,
            stepTableArray,
            indeterminate,
            checkAll,
            activeOperation,
            nextStep,
            init,
            clearScreeningConditions,
            getTableData,
            downloadExtendInfo,
            checkChange,
            onCheckAllChange,
            showFill,
            showUploadDrawer,
            showExample,
            showSupportPic,
            uploadSingleSupport,
            proveText,
            manager,
            isCPO,
            jumpToUploadUsedCarPage
        };
    },
});
