
import { defineComponent, ref, onMounted } from "vue";
import {
  extendEdit,
  saveInfoExtend,
  getExtendInfo,
} from "@/API/claim/delaerClaim/delaer";
import { message } from "ant-design-vue";
export default defineComponent({
  //
  emits: ["close-drawer", "fresh-table"],

  setup(props, ctx) {
    const afterVisibleChange = (bool: boolean) => {
      console.log("visible", bool);
    };

    const groupID = ref("");
    const progDesignOfferTypeId = ref("");
    const vinNo = ref("");
    const dealerCode = ref("");

    const isShow = ref(false);

    //是否显示
    const visible = ref(false);

    interface ExtendInfoType {
      content: string;
      defaultValue: string;
      enumValue: string;
      fieldNameCn: string;
      fieldNameEn: string;
      fieldType: string;
      id: string;
      isMandatory: boolean;
      maxValue: number | string;
      minValue: number | string;
      paraExtendInfoId: string;
      progDesignId: string;
      progDesignOfferTypeId: string;
      progDesignOfferTypeName: string;
      vinNo: string;
      enumValueList?: string[];
      error: boolean; //是否输入错误，进行判断
      errorMessage: string; //错误信息显示的内容
    }

    const data = ref<ExtendInfoType[]>([]);

    //限制只能输入数字和小数点和符号3
    const handleInput = (e: any) => {
      const a = e.key.replace(/[^\d.-]/g, "");
      if (!a && e.keyCode !== 8) {
        e.preventDefault();
      }
    };

    interface OneItem {
      claimParaExtendInfoId: string;
      content: string;
      id: string;
    }

    interface ExpandInfo {
      dealerCode: string;
      progDesignId: string;
      progDesignOfferTypeId: string;
      vinNo: string;
      dealerExtendInfoLst: OneItem[];
    }

    const checkExtend = (item: ExtendInfoType) => {
      item.error = false;

      if (item.fieldType === "Text") {
        if (
          item.isMandatory &&
          (item.content === null || (item.content as string).trim() === "")
        ) {
          item.error = true;
          item.errorMessage = "必填项请填写";
          return false;
        }
        if (
          !item.isMandatory &&
          (item.content === null || (item.content as string).trim() === "")
        ) {
          return true;
        }

        if (item.maxValue !== null && item.maxValue !== "") {
          if (item.content.length > item.maxValue) {
            item.error = true;
            item.errorMessage = `文档长度最大为${item.maxValue}`;
            return false;
          }
        }
        if (item.minValue !== null && item.minValue !== "") {
          if (item.content.length < item.minValue) {
            item.error = true;
            item.errorMessage = `文档长度最小为${item.minValue}`;
            return false;
          }
        }

        // if (item.content.length>item.maxValue || item.content.length<item.minValue){
        //   item.error = true;
        //   item.errorMessage = `文档长度为${item.minValue}——${item.maxValue}`
        //   return false;
        // }
      }

      if (item.fieldType === "Number") {
        if (
          item.isMandatory &&
          (item.content === null || (item.content as string).trim() === "")
        ) {
          item.error = true;
          item.errorMessage = "必填项请填写";
          return false;
        }
        if (
          !item.isMandatory &&
          (item.content === null || (item.content as string).trim() === "")
        ) {
          return true;
        }
        // if (item.content.length>item.maxValue || item.content.length<item.minValue){
        //   item.error = true;
        //   item.errorMessage = `文档数值应为${item.minValue}——${item.maxValue}`
        //   return false;
        // }

        if (item.maxValue !== null && item.maxValue !== "") {
          if (parseInt(item.content) > item.maxValue) {
            item.error = true;
            item.errorMessage = `数值最大为${item.maxValue}`;
            return false;
          }
        }
        if (item.minValue !== null && item.minValue !== "") {
          if (parseInt(item.content) < item.minValue) {
            item.error = true;
            item.errorMessage = `数值最小为${item.minValue}`;
            return false;
          }
        }
      }

      if (item.fieldType === "Enumeration") {
        if (
          item.isMandatory &&
          (item.content === null || (item.content as string).trim() === "")
        ) {
          item.error = true;
          item.errorMessage = "必填项请选择";
          return false;
        }
        if (
          !item.isMandatory &&
          (item.content === null || (item.content as string).trim() === "")
        ) {
          return true;
        }
      }

      if (item.fieldType === "Date") {
        if (
          item.isMandatory &&
          (item.content === null || (item.content as string).trim() === "")
        ) {
          item.error = true;
          item.errorMessage = "必填项请选择";
          return false;
        }
        if (
          !item.isMandatory &&
          (item.content === null || (item.content as string).trim() === "")
        ) {
          return true;
        }
        const value = Date.parse(item.content);
        // if (value >= Date.parse(item.maxValue as string) ||value <= Date.parse(item.minValue as string)){
        //   item.error = true;
        //   item.errorMessage = `日期大小应在${item.minValue}-${item.maxValue}之间`
        //   return false;
        // }
        if (item.maxValue !== null) {
          if (value > Date.parse(item.maxValue as string)) {
            item.error = true;
            item.errorMessage = `日期最大为${item.maxValue}`;
            return false;
          }

          if (value < Date.parse(item.minValue as string)) {
            item.error = true;
            item.errorMessage = `日期最小为${item.minValue}`;
            return false;
          }
        }
      }

      return true;
    };

    //保存事件
    const onSave = () => {
      const param: ExpandInfo = {
        progDesignId: groupID.value,
        progDesignOfferTypeId: progDesignOfferTypeId.value,
        vinNo: vinNo.value,
        dealerExtendInfoLst: [],
        dealerCode: dealerCode.value,
      };

      let count = 0;
      for (let i = 0; i < data.value.length; i++) {
        const item = data.value[i];
        const sign = checkExtend(item);
        if (!sign) {
          count++;
        }
      }

      if (count > 0) {
        message.error("填写扩展信息错误，请修改后保存");
        return;
      }

      // let sign = 0;

      // data.value.forEach((element) => {
      //   console.log(element.content)
      //   if (element.isMandatory === true && (element.content === null || element.content.length < 1)) {
      //     message.error(`${element.fieldNameCn}为必填项目，请填写`);
      //     sign++;
      //     return;
      //   }

      //   if (element.fieldType === "Text") {
      //     if (
      //       element.content !=="" && (element.content.length > (element.maxValue as number) * 1 ||element.content.length < (element.minValue as number) * 1)
      //     ) {
      //       message.error(
      //         `${element.fieldNameCn}长度应在${element.minValue}-${element.maxValue}之间`
      //       );
      //       sign++;
      //       return;
      //     }
      //   }

      //   if (element.fieldType === "Number") {
      //     const value = parseFloat(element.content);
      //     if (
      //        element.content !=="" && (
      //       value >= (element.maxValue as number) * 1 ||
      //       value <= (element.minValue as number) * 1)
      //     ) {
      //       message.error(
      //         `${element.fieldNameCn}数字大小应在${element.minValue}-${element.maxValue}之间`
      //       );
      //       sign++;
      //       return;
      //     }
      //   }

      //   if (element.fieldType === "Date") {
      //     const value = Date.parse(element.content);
      //     if (
      //       element.content !=="" && (
      //       value >= Date.parse(element.maxValue as string) ||
      //       value <= Date.parse(element.minValue as string))
      //     ) {
      //       message.error(
      //         `${element.fieldNameCn}日期大小应在${element.minValue}-${element.maxValue}之间`
      //       );
      //       sign++;
      //       return;
      //     }
      //   }
      // });

      // if (sign !== 0){
      //   return;
      // }

      data.value.forEach((element) => {
        const one = {
          claimParaExtendInfoId: element.paraExtendInfoId,
          content: element.content,
          id: element.id,
          dealerCode: dealerCode.value,
        };
        param.dealerExtendInfoLst.push(one);
      });

      saveInfoExtend(param).then((res: any): void => {
        if (res.code === "0") {
          message.success("数据保存成功");
          visible.value = false;
          ctx.emit("fresh-table");
        } else {
          message.error(res.data[0]);
        }
      });
    };

    //关闭抽屉事件
    const closeThisDrawer = () => {
      // ctx.emit('close-drawer','isShowSingleInformation');
      visible.value = false;
      // on-colse
    };
    //编辑填写扩展信息
    const editExtend = (
      propid: string,
      item: any,
      offerTypeId: string,
      showButton: boolean,
      dealerCodeA: string
    ) => {
      groupID.value = propid;
      progDesignOfferTypeId.value = offerTypeId;
      vinNo.value = item.vinNo;
      dealerCode.value = dealerCodeA;
      isShow.value = showButton;

      if (item.extendedInfo === "1") {
        console.log("查看");

        const param = {
          prodId: groupID.value,
          offerTypeId: progDesignOfferTypeId.value,
          vinNo: vinNo.value,
          dealerCode: dealerCode.value,
        };
        getExtendInfo(param).then((res: any): void => {
          console.log(res);
          const tdata = res;

          for (let i = 0; i < tdata.length; i++) {
            if (tdata[i].fieldType === "Enumeration") {
              tdata[i].enumValueList = tdata[i].enumValue.split(",");
            }
          }
          data.value = tdata;
          visible.value = true;
        });
      } else {
        console.log("编辑");

        extendEdit({ prodId: propid }).then((res: any): void => {
          console.log(res);
          const tdata = res;

          for (let i = 0; i < tdata.length; i++) {
            if (tdata[i].fieldType === "Enumeration") {
              tdata[i].enumValueList = tdata[i].enumValue.split(",");
            }
            tdata[i].content = "";
          }
          data.value = tdata;
          visible.value = true;
        });
      }
    };

    const spinWrapper = document.querySelector(
      ".spingWrapper .ant-spin-container"
    );

    return {
      isShow,
      visible,
      editExtend,
      closeThisDrawer,
      data,
      onSave,
      afterVisibleChange,
      handleInput,
      checkExtend,
      spinWrapper,
    };
  },
});
//.ant-form-item
